<template>
  <div>
    <b-card
      border-variant="primary"
      text-variant="white"
    >
      <div>
        <b-row>   
          <b-col cols="12" md="8">
            <validation-observer 
              ref="assignmentFileRulesValidation"
            >
              <b-form>
                <validation-provider
                  #default="{ errors }"
                  name="archivo de asignación"
                  rules="required"
                >
                  <b-form-group
                    :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null" 
                  >
                    <b-form-file
                      v-model="fileglosas"
                      placeholder="Cargue su archivo de glosas aquí..."
                      drop-placeholder="Arrastre archivo de glosas aquí..."
                      accept=".xlsx"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col cols="12" md="4">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              @click="saveGlosasFile"
              class="w-100"
            >
              <div v-if="loadGlosasFile">
                <b-spinner small/>
              </div>
              <div v-else>
                <feather-icon
                  icon="SunriseIcon"
                  class="mr-50"
                />
                <span class="align-middle">
                  Cargar Glosas
                </span>
              </div>
              
            </b-button>
          </b-col>          
        </b-row>
      </div>
    </b-card>
    <error-import ref="ErrorImport" />
  </div>
</template>
    
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BFormFile, BFormTextarea, BFormDatepicker, BForm, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ErrorImport from './ErrorImport.vue';
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BFormDatepicker,
    BForm,
    localize,
    BSpinner,
    ErrorImport,
  },
  data() {
    return {
      
      refreshloading: false,
      
      loadGlosasFile: false,
      fileglosas: null,
      qualityCheckerList: [],
      newForm: true,
      today: new Date(),
    }
  },
  created() {

  },
  methods: {
    saveGlosasFile() {
      localize('es', es)
      this.$refs.assignmentFileRulesValidation.validate().then(success => {
        if (success) {
          this.loadGlosasFile = true
          let formData = new FormData()
          formData.append('file', this.fileglosas)
          this.$http.post('coordination/import-massive-glosas', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            this.loadGlosasFile = false
            this.fileglosas = null
            this.$refs.assignmentFileRulesValidation.reset()
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                    confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          })
          .catch((error) => {
            this.fileglosas = null
            this.loadGlosasFile = false
            this.$refs.assignmentFileRulesValidation.reset()
            this.viewErrors(error.response.data.errors)
            this.$refs.ErrorImport.openModalErrors();
          })
        }
      })
    },
    viewErrors(errorsData) {
      this.$refs.ErrorImport.errorStatus = true
      this.$refs.ErrorImport.dataTable = errorsData
      this.$refs.ErrorImport.totalRows = errorsData.length
    },
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>